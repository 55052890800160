import { Controller } from "stimulus"
import Rellax from 'rellax';

export default class extends Controller {
  static targets = [];

  connect() {
    this.parallax = new Rellax(this.element, {
      speed: -4,
      wrapper: this.element.closest(".issue__slide"),
      breakpoints: [576, 768, 1201]
    });
  }

  disconnect() {
    this.parallax.destroy();
  }
}