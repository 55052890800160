import { Controller } from "stimulus";
import { loadStripe } from "@stripe/stripe-js/pure";

export default class extends Controller {
  static targets = [
    "stripePaymentMethodId",
    "partnerCode",
    "paymentMethodSepa",
    "paymentMethodCard",
    "paymentMethodSepaRadioButton",
    "paymentMethodCardRadioButton",
    "company",
    "email",
    "countryCode",
    "taxId",
    "taxIdArea",
    "submitButton",
  ];

  static values = {
    stripeClientSecret: String,
    stripePublicKey: String,
    buttonText: String,
  };

  stripe;
  iban;
  card;

  connect() {
    // console.log(this.stripeClientSecretValue);
    // console.log(this.stripePublicKeyValue);
    // console.log(this.stripePaymentMethodIdTarget.value);
    // console.log(this.partnerCodeTarget.value);

    this.setTaxVisibility();

    (async () => {
      this.stripe = await loadStripe(this.stripePublicKeyValue);

      const elements = this.stripe.elements();

      const style = {
        base: {
          color: "#ffffff",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
          ":-webkit-autofill": {
            color: "#32325d",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
          ":-webkit-autofill": {
            color: "#fa755a",
          },
        },
      };

      this.iban = elements.create("iban", {
        style,
        supportedCountries: ["SEPA"],
        hideIcon: false,
        iconStyle: "solid",
        // placeholderCountry: "CH", // AT, DE, CH
      });

      this.iban.mount("#iban-element");

      this.card = elements.create("card", {
        style,
        hidePostalCode: true,
        iconStyle: "solid",
      });

      this.card.mount("#card-element");
    })();
  }

  submit(event) {
    event.preventDefault();

    const _this = this;

    this.disableSubmitButton();

    if (this.companyTarget.value === "") {
      alert("Bitte geben Sie noch Ihren Firmennamen ein.");
      if (typeof Rollbar !== "undefined") {
        Rollbar.warning("Firmenname nicht eingegeben");
      }
      this.enableSubmitButton();
      return;
    }

    if (this.emailTarget.value === "") {
      alert("Bitte geben Sie noch Ihre E-Mail-Adresse ein.");
      if (typeof Rollbar !== "undefined") {
        Rollbar.warning("E-Mail-Adresse nicht eingegeben");
      }
      this.enableSubmitButton();
      return;
    }

    if (this.countryCodeTarget.value === "AT") {
      if (this.taxIdTarget.value !== "") {
        if (this.taxIdTarget.value.length != 11) {
          alert(
            "Bitte gib eine gültige Umsatzsteuer-ID für Österreich an (Beispiel: ATU12345678)"
          );
          if (typeof Rollbar !== "undefined") {
            Rollbar.warning("Umsatzsteuer-ID ungültig Länge");
          }
          this.enableSubmitButton();
          return;
        }

        if (this.taxIdTarget.value.substr(0, 3) !== "ATU") {
          alert(
            "Bitte gib eine gültige Umsatzsteuer-ID für Österreich an (Beispiel: ATU12345678)"
          );
          if (typeof Rollbar !== "undefined") {
            Rollbar.warning("Umsatzsteuer-ID AT startet nicht mit ATU");
          }
          this.enableSubmitButton();
          return;
        }
      }
    }

    if (_this.paymentMethodSepaRadioButtonTarget.checked) {
      _this.stripe
        .confirmSepaDebitSetup(_this.stripeClientSecretValue, {
          payment_method: {
            sepa_debit: this.iban,
            billing_details: {
              name: _this.companyTarget.value,
              email: _this.emailTarget.value,
            },
          },
        })
        .then(function (result) {
          // console.log(result);

          if (result.error) {
            alert(result.error.message);
            if (typeof Rollbar !== "undefined") {
              Rollbar.error(
                "Stripe SEPA Error Result: " + result.error.message,
                result
              );
            }
            _this.enableSubmitButton();
            return;
          }

          _this.stripePaymentMethodIdTarget.value =
            result.setupIntent.payment_method;
          _this.element.submit();
        });
    } else {
      _this.stripe
        .confirmCardSetup(_this.stripeClientSecretValue, {
          payment_method: {
            card: this.card,
            billing_details: {
              name: _this.companyTarget.value,
              email: _this.emailTarget.value,
            },
          },
        })
        .then(function (result) {
          // console.log(result);

          if (result.error) {
            alert(result.error.message);
            if (typeof Rollbar !== "undefined") {
              Rollbar.error(
                "Stripe CARD Error Result: " + result.error.message,
                result
              );
            }
            _this.enableSubmitButton();
            return;
          }

          _this.stripePaymentMethodIdTarget.value =
            result.setupIntent.payment_method;
          _this.element.submit();
        });
    }
  }

  showPaymentMethodSepa() {
    this.paymentMethodSepaTarget.classList.remove("payment-method--hidden");
    this.paymentMethodCardTarget.classList.add("payment-method--hidden");
  }

  showPaymentMethodCard() {
    this.paymentMethodSepaTarget.classList.add("payment-method--hidden");
    this.paymentMethodCardTarget.classList.remove("payment-method--hidden");
  }

  setTaxVisibility() {
    if (this.countryCodeTarget.value === "AT") {
      this.taxIdAreaTarget.classList.remove("tax-id-area--hidden");
    } else {
      this.taxIdAreaTarget.classList.add("tax-id-area--hidden");
    }
  }

  disableSubmitButton() {
    this.submitButtonTarget.innerHTML = "bitte warten...";
    this.submitButtonTarget.disabled = true;
  }

  enableSubmitButton() {
    this.submitButtonTarget.innerHTML = this.buttonTextValue;
    this.submitButtonTarget.disabled = false;
  }
}
