import { Controller } from "stimulus";

export default class extends Controller {
  // static targets = ["content"]

  connect() {
    // console.log("connect help controller");
  }

  start(e) {
    e.preventDefault();
    Beacon("open");
  }

  disconnect() {}
}
