import { Controller } from "stimulus";
import { loadStripe } from "@stripe/stripe-js/pure";

export default class extends Controller {
  static targets = [
    "stripePaymentMethodId",
    "paymentMethodSepa",
    "paymentMethodCard",
    "paymentMethodSepaRadioButton",
    "paymentMethodCardRadioButton",
    "submitButton",
  ];

  static values = {
    stripeClientSecret: String,
    stripePublicKey: String,
    customerEmail: String,
    customerCompany: String,
  };

  stripe;
  iban;
  card;

  connect() {
    // console.log(this.stripeClientSecretValue);
    // console.log(this.stripePublicKeyValue);
    // console.log(this.customerEmailValue);
    // console.log(this.customerCompanyValue);
    // console.log(this.stripePaymentMethodIdTarget.value);

    (async () => {
      this.stripe = await loadStripe(this.stripePublicKeyValue);

      const elements = this.stripe.elements();

      const style = {
        base: {
          color: "#ffffff",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
          ":-webkit-autofill": {
            color: "#32325d",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
          ":-webkit-autofill": {
            color: "#fa755a",
          },
        },
      };

      this.iban = elements.create("iban", {
        style,
        supportedCountries: ["SEPA"],
        hideIcon: false,
        iconStyle: "solid",
        // placeholderCountry: "CH", // AT, DE, CH
      });

      this.iban.mount("#iban-element");

      this.card = elements.create("card", {
        style,
        hidePostalCode: true,
        iconStyle: "solid",
      });

      this.card.mount("#card-element");
    })();
  }

  submit(event) {
    event.preventDefault();

    // console.log("submit");

    const _this = this;

    this.disableSubmitButton();

    if (_this.paymentMethodSepaRadioButtonTarget.checked) {
      _this.stripe
        .confirmSepaDebitSetup(_this.stripeClientSecretValue, {
          payment_method: {
            sepa_debit: this.iban,
            billing_details: {
              name: _this.customerCompanyValue,
              email: _this.customerEmailValue,
            },
          },
        })
        .then(function (result) {
          // console.log(result);

          if (result.error) {
            alert(result.error.message);
            if (typeof Rollbar !== "undefined") {
              Rollbar.error(
                "Stripe SEPA Error Result: " + result.error.message,
                result
              );
            }
            _this.enableSubmitButton();
            return;
          }

          _this.stripePaymentMethodIdTarget.value =
            result.setupIntent.payment_method;
          _this.element.submit();
        });
    } else {
      _this.stripe
        .confirmCardSetup(_this.stripeClientSecretValue, {
          payment_method: {
            card: this.card,
            billing_details: {
              name: _this.customerCompanyValue,
              email: _this.customerEmailValue,
            },
          },
        })
        .then(function (result) {
          // console.log(result);

          if (result.error) {
            alert(result.error.message);
            if (typeof Rollbar !== "undefined") {
              Rollbar.error(
                "Stripe CARD Error Result: " + result.error.message,
                result
              );
            }
            _this.enableSubmitButton();
            return;
          }

          _this.stripePaymentMethodIdTarget.value =
            result.setupIntent.payment_method;
          _this.element.submit();
        });
    }
  }

  showPaymentMethodSepa() {
    this.paymentMethodSepaTarget.classList.remove("payment-method--hidden");
    this.paymentMethodCardTarget.classList.add("payment-method--hidden");
  }

  showPaymentMethodCard() {
    this.paymentMethodSepaTarget.classList.add("payment-method--hidden");
    this.paymentMethodCardTarget.classList.remove("payment-method--hidden");
  }

  disableSubmitButton() {
    this.submitButtonTarget.innerHTML = "bitte warten...";
    this.submitButtonTarget.disabled = true;
  }

  enableSubmitButton() {
    this.submitButtonTarget.innerHTML = "Zahlungsart aktualisieren";
    this.submitButtonTarget.disabled = false;
  }
}
