import Swiper, {Navigation, Keyboard, Mousewheel, Scrollbar, Thumbs, HashNavigation} from "swiper";
Swiper.use([ Navigation, Keyboard, Mousewheel, Scrollbar, Thumbs, HashNavigation ]);
//import "swiper/swiper-bundle.css";
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["nextButton", "prevButton", "pagination"]

  connect() {
    this.paginationSwiper = new Swiper(this.paginationTarget, {
      slidesPerView: "auto",
      centerInsufficientSlides: true,
      watchSlidesProgress: true,
      watchSlidesProgress: true
      // centeredSlides: true
    });

    this.swiper = new Swiper(this.element, {
      spaceBetween: 0,
      simulateTouch: false, // can't select text on desktop otherwise
      navigation: {
        nextEl: this.nextButtonTargets,
        prevEl: this.prevButtonTargets,
      },
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
      mousewheel: {
        forceToAxis: true,
        thresholdDelta: 30,
        thresholdTime: 300
      },
      thumbs: {
        swiper: this.paginationSwiper
      },
      hashNavigation: true
    });

    this.swiper.on("slideChange", this.onChange.bind(this));
    this.onChange();
  }

  slideTo(hash) {
    this.swiper.slides.forEach((slide, index) => {
      if(slide.dataset?.hash == hash)
        return this.swiper.slideTo( index );
    });
  }

  slide(e) {
    e.preventDefault();
    const hash = e.target.closest("a")?.dataset?.slideTo;
    if(hash) this.slideTo(hash);
  }

  onChange() {
    document.body.dataset.issueSliderIndex = this.swiper.activeIndex;
    //this.swiper.emit("issue::slideChange", this.swiper.activeIndex);
  }

  disconnect() {
    this.swiper.destroy();
    this.swiper = null;
  }
}
