import Swiper, { Navigation, Mousewheel, Pagination, Autoplay } from "swiper";
Swiper.use([Navigation, Mousewheel, Pagination, Autoplay]);
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container"];

  connect() {
    this.sliderContainer = this.hasContainerTarget ? this.containerTarget : this.element;
    this.swiper = new Swiper(this.sliderContainer, {
      spaceBetween: 0,
      simulateTouch: true,
      width: null,
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        bulletClass: "pagination__thumbnail",
        bulletActiveClass: "active",
        clickable: true,
        renderBullet: function (index, className) {
          const img = this.slides[index].querySelector("img");
          return `<span class="${className}" style="background-image: url(${img?.src})" data-action="click->slider#disableAutoplay"></span>`
        }
      },
      autoplay: {
        delay: 4000
      }
    });
    // Propagate slide changes
    this.swiper.on("slideChange", this.onChange.bind(this));
    this.onChange();
    // Toggle autoplay depending on visibility
    this.swiper.autoplay.stop()
    this.observer = new IntersectionObserver(this.onViewIntersection.bind(this), {});
    this.observer.observe(this.containerTarget);
  }

  onChange() {
   this.element.dataset.sliderIndex = this.swiper.activeIndex;
  }

  onViewIntersection(entries) {
    const entry = entries[0];
    if (entry.intersectionRatio > 0) // In viewport
      this.swiper?.autoplay.start(); // or continueAutoplay()
    else  // Out of viewport
      this.swiper?.autoplay.stop();
  }

  pauseAutoplay() {
    this.swiper.autoplay.stop();
  }

  continueAutoplay() {
    if(!this.autoplayDisabled)
      this.swiper.autoplay.start();
  }

  disableAutoplay() {
    this.autoplayDisabled = true
    this.swiper.autoplay.stop();
  }

  disconnect() {
    this.swiper.destroy();
    this.swiper = null;
  }
}
