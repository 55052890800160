import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["container"]

  initialize() {
    const mojs = require("@mojs/core");
  }

  connect() {
    const container = this.hasContainerTarget ? this.containerTarget : this.element;

    this.burstAnimation = new mojs.Burst({
      parent: container,
      className: "particles",
      left: "50%",
      top: "50%",
      radius: { 4: 22 },
      angle: 45,
      count: 14,
      children: {
        radius: 2.5,
        fill: '#FD7932',
        scale:{ 1: 0, easing: 'quad.in' },
        pathScale: [ 0.8, null ],
        degreeShift:[ 13, null ],
        duration: [ 500, 700 ],
        easing:  'quint.out'
      }
    });
  }

  burst(e) {
    this.burstAnimation.replay();
  }

  disconnect() {
    this.burstAnimation = null;
  }
}
