import { Controller } from "stimulus"
import morphdom from "morphdom";

export default class extends Controller {
  static targets = []

  connect() {
  }

  updateContent(e) {
    const [res, status] = e.detail;
    this.element.innerHTML = res.data;
  }

  morphContent(e) {
    const [res, status] = e.detail;
    morphdom(this.element, res.data, {
      onBeforeNodeDiscarded: (el) => {
        if (el?.classList?.contains("particles")) return false;
      },
    });
  }

  onError(e) {
    const [text, status] = e.detail;
    console.error(e.detail)
  }

  reloadPage() {
    location.reload();
  }

  removeContent() {
    this.element.remove();
  }

  disconnect() {

  }
}
