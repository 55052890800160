import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content"]

  connect() {

  }

  copy(e) {
    const text = this.contentTarget.textContent;
    // create dummy textarea to allow copying
    const el = document.createElement("textarea");
    el.value = text;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    el.style.zIndex = "-100";
    document.body.appendChild(el);
    el.select();
    el.setSelectionRange(0, 99999);

    document.execCommand("copy");
    document.body.removeChild(el);

    this.showMessage(e.target.closest("[data-action]"), "Der Inhalt wurde kopiert.");
  }

  showMessage(node, message) {
    const el = document.createElement("div");
    el.classList.add("tooltip-message");
    el.innerHTML = message;
    node.append(el);

    window.setTimeout(()=> el.remove(), 1800);
  }

  disconnect() {

  }
}
