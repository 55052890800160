import { Controller } from "stimulus";
const scrollLock = require('body-scroll-lock');

export default class extends Controller {
  static targets = ["backdrop","mobileMenu", "welcome"]
  static classes = ["open"]

  toggle( overlay ) {
    overlay.contains(this.openClass) ? this.closeBackdrop() : this.openBackdrop();
    overlay.toggle(this.openClass);
  }

  /**
   * Either open or close the Mobile Menu depending on its current state
   * @param e The click event
   */
  toggleMobileMenu(e) {
    this.toggle( this.mobileMenuTarget.classList );
  }

  /**
   * Either open or close the welcome overlay depending on its current state
   * @param e The click event
   */
  toggleWelcome(e) {
    if(this.hasWelcomeTarget)
      this.toggle( this.welcomeTarget.classList);
  }

  /**
   * Display Backdrop
   */
  openBackdrop() {
    this.backdropTarget.classList.add(this.openClass);
    scrollLock.disableBodyScroll(this.mobileMenuTarget);
  }

  /**
   * Hide Backdrop
   */
  closeBackdrop() {
    this.backdropTarget.classList.remove(this.openClass);
    scrollLock.enableBodyScroll(this.mobileMenuTarget);
  }

  /**
   * Hide all overlays and the backdrop
   */
  close() {
    this.closeBackdrop();
    this.mobileMenuTarget?.classList.remove(this.openClass);
    if(this.hasWelcomeTarget) this.welcomeTarget?.classList.remove(this.openClass);

    if(this.hasWelcomeTarget && !this.welcomeTarget.classList.contains(this.openClass)) {
      this.welcomeTarget.addEventListener("transitionend", () => {
        this.welcomeTarget.parentNode.removeChild(this.welcomeTarget);
      });
    }
  }
}
