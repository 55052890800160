import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content"]
  static classes = ["open"]

  connect() {
    // if(this.hasContentTarget)
    //   this.contentTargets[0]?.parentNode.classList.add(this.openClass);
  }

  toggle(e) {
    var handle = e.target.closest(".item__handle");
    var content = this.getContentElementFor(handle);
    if(!content) return;
    handle.parentNode.classList.contains(this.openClass) ? this.close(content) : this.open(content);
  }

  open(content) {
    content.style.maxHeight = content.scrollHeight + "px";
    content.addEventListener("transitionend", () => {
      content.style.maxHeight = this.isOpen(content) ? "none" : null;
    }, { once: true });
    content.parentNode.classList.add(this.openClass);
    this.closeAll(content);
  }

  close(content) {
    content.style.maxHeight = content.scrollHeight + "px";
    setTimeout(() => content.style.maxHeight = null, 50);
    content.parentNode.classList.remove(this.openClass);
  }

  closeAll(except=null) {
    this.contentTargets.forEach((el) => {
      if(this.isOpen(el) && el != except) this.close(el);
    });
  }

  isOpen(element) {
    return element.parentNode.classList.contains(this.openClass);
  }

  getContentElementFor(handle) {
    return this.contentTargets.find(el => el.parentNode == handle.parentNode);
  }

}